import * as React from 'react';

function SvgAccountBalanceWallet2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M21 18v1c0 1.1-.9 2-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h14c1.1 0 2 .9 2 2v1h-9a2 2 0 00-2 2v8a2 2 0 002 2h9zm-9-2h10V8H12v8zm4-2.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"
        fill="url(#account-balance-wallet_2_svg__paint0_linear_3937_45901)"
      />
      <defs>
        <linearGradient
          id="account-balance-wallet_2_svg__paint0_linear_3937_45901"
          x1={3}
          y1={12}
          x2={22}
          y2={12}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D331FB" />
          <stop offset={1} stopColor="#37BAFC" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoSvgAccountBalanceWallet2 = React.memo(SvgAccountBalanceWallet2);
export default MemoSvgAccountBalanceWallet2;

import * as React from 'react';

function SvgClose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 25" {...props}>
      <defs>
        <clipPath id="Close_svg__a">
          <path d="M0 0h25v25H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#Close_svg__a)">
        <path
          d="M23 5.014L20.986 3 13 10.986 5.014 3 3 5.014 10.986 13 3 20.986 5.014 23 13 15.014 20.986 23 23 20.986 15.014 13z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

const MemoSvgClose = React.memo(SvgClose);
export default MemoSvgClose;

import React from 'react';

import { Box } from '@material-ui/core';
import { useRouter } from 'next/router';

import { GameType } from 'services/api/definitions/game.definitions';

import SimpleBottomNavigation from 'components/@navigation/BottomNavigation/BottomNavigation';
import Header from 'components/@navigation/Header';
import { HeaderVariant } from 'components/@navigation/Header/Header';

type NavigationLayoutProps = {
  headerVariant?: HeaderVariant | 'none';
  behaviour?: 'fixed' | 'scroll';
  gameType?: GameType;
  hideBottomNavigation?: boolean;
  backAction?: () => void;
};

const NavigationLayout: React.FC<NavigationLayoutProps> = ({
  children,
  headerVariant = 'default',
  gameType,
  behaviour = 'scroll',
  hideBottomNavigation = false,
  backAction,
}) => {
  const router = useRouter();

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {headerVariant !== 'none' && (!gameType || gameType === GameType.firstParty) ? (
        <>
          <Header
            variant={gameType === GameType.firstParty ? 'backbutton' : headerVariant}
            appBarPosition={behaviour === 'fixed' ? 'relative' : undefined}
            backAction={backAction}
          />
          {behaviour !== 'fixed' && <Box height={56} flexShrink={0} />}
          <Box flex={1}>{children}</Box>
        </>
      ) : (
        <Box flex={1}>{children}</Box>
      )}
      {!hideBottomNavigation && (
        <>
          <Box height={56} flexShrink={0} />
          <SimpleBottomNavigation currentPath={router.pathname} />
        </>
      )}
    </Box>
  );
};

export default NavigationLayout;

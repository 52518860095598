import { Typography, withStyles } from '@material-ui/core';

const GradientTypography = withStyles({
  root: {
    background: '-webkit-linear-gradient(0, #D331FB 0%, #37BAFC 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },
})(Typography);

export default GradientTypography;

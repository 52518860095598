import React from 'react';

import { Box, BoxProps } from '@material-ui/core';
import Image from 'next/image';

interface GameIconProps extends BoxProps {
  size?: number;
  src: string;
  alt?: string;
  blurDataURL?: string;
  imagesAboveFold?: boolean;
}

const GameIcon = ({
  size = 40,
  src,
  alt = 'Icon',
  blurDataURL,
  borderRadius = 12,
  mr = 2,
  imagesAboveFold,
  ...rest
}: GameIconProps) => (
  <Box
    borderRadius={borderRadius}
    mr={mr}
    overflow="hidden"
    flexShrink={0}
    width={size}
    height={size}
    position="relative"
    {...rest}
  >
    <Image
      layout="fill"
      src={src}
      objectFit="cover"
      alt={alt}
      blurDataURL={blurDataURL}
      placeholder={blurDataURL ? 'blur' : 'empty'}
      priority={imagesAboveFold}
    />
  </Box>
);

export default GameIcon;

import React from 'react';

import { useScrollTrigger } from '@material-ui/core';

interface ElevationScrollProps {
  isMenuOpen?: boolean;
  children: React.ReactElement;
  disabled?: boolean;
}

export default function ElevationScroll(props: ElevationScrollProps) {
  const { children, isMenuOpen, disabled = false } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger && !disabled ? 2 : 0,
    style: {
      backgroundColor: disabled || trigger || isMenuOpen ? undefined : 'transparent',
      transition: disabled || trigger || isMenuOpen ? 'none' : 'background-color 500ms ease-in',
    },
  });
}

import * as React from 'react';

function SvgFavourites2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 25" {...props}>
      <defs>
        <clipPath id="Favourites_2_svg__c">
          <path
            d="M0 0h20v20H0z"
            transform="translate(186 3106)"
            fill="url(#Favourites_2_svg__a)"
          />
        </clipPath>
        <clipPath id="Favourites_2_svg__b">
          <path d="M0 0h25v25H0z" />
        </clipPath>
        <linearGradient
          id="Favourites_2_svg__a"
          x1={0.285}
          y1={0.881}
          x2={0.932}
          y2={0.094}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#d331fb" />
          <stop offset={0.1} stopColor="#c33efb" />
          <stop offset={0.2} stopColor="#b34cfb" />
          <stop offset={0.3} stopColor="#a459fb" />
          <stop offset={0.4} stopColor="#9467fb" />
          <stop offset={0.5} stopColor="#8575fb" />
          <stop offset={0.6} stopColor="#7583fb" />
          <stop offset={0.7} stopColor="#6590fb" />
          <stop offset={0.8} stopColor="#569efb" />
          <stop offset={0.9} stopColor="#46acfb" />
          <stop offset={1} stopColor="#36bafb" />
        </linearGradient>
      </defs>
      <g clipPath="url(#Favourites_2_svg__b)">
        <g transform="translate(-183 -3103)" clipPath="url(#Favourites_2_svg__c)">
          <g transform="translate(185.489 3106.69)" fill="url(#Favourites_2_svg__a)">
            <path d="M11.108 17.88H9.917l-7.295-7.443a5.792 5.792 0 010-8.034 5.13 5.13 0 011.064-.823 5.5 5.5 0 016.826.827l.165-.165a5.5 5.5 0 017.728.174 5.792 5.792 0 010 8.026zM6.566 2.345a3.79 3.79 0 00-2.011.571 3.467 3.467 0 00-.745.6 4.137 4.137 0 000 5.734l6.7 6.876 6.71-6.884a4.137 4.137 0 000-5.726 3.847 3.847 0 00-5.395-.124 3.922 3.922 0 00-.6.736H9.822a3.814 3.814 0 00-3.256-1.783z" />
            <path d="M11.322 18.38H9.707l-7.445-7.6a6.292 6.292 0 010-8.731 5.631 5.631 0 011.166-.9 6 6 0 017.081.568 6 6 0 018.255.349 6.292 6.292 0 010 8.72zm-1.191-1h.771l7.151-7.3a5.292 5.292 0 000-7.33 5 5 0 00-7.031-.154l-.517.517-.354-.361A5 5 0 003.95 2l-.012.007a4.631 4.631 0 00-.961.745 5.292 5.292 0 000 7.338zm.386-.546l-7.06-7.243a4.637 4.637 0 010-6.429 3.959 3.959 0 01.845-.677 4.286 4.286 0 012.26-.644h.03a4.29 4.29 0 013.491 1.779h.877a4.427 4.427 0 01.523-.6 4.347 4.347 0 016.1.138 4.627 4.627 0 010 6.42zM6.556 2.845a3.287 3.287 0 00-1.736.5l-.008.005a2.967 2.967 0 00-.637.51 3.628 3.628 0 000 5.044l6.342 6.506 6.352-6.517a3.628 3.628 0 000-5.031 3.347 3.347 0 00-4.691-.106 3.418 3.418 0 00-.524.64l-.147.232H9.542l-.147-.238a3.3 3.3 0 00-2.8-1.546z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

const MemoSvgFavourites2 = React.memo(SvgFavourites2);
export default MemoSvgFavourites2;

import React, { useEffect, useMemo } from 'react';

import { BottomNavigationAction } from '@material-ui/core';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import Paper from '@material-ui/core/Paper';
import { useIntl } from 'react-intl';
import { Routes } from 'routes';

import GradientTypography from 'components/@common/GradientTypography';
import {
  AccountBalanceWallet1,
  AccountBalanceWallet2,
  Discover1,
  Discover2,
  Favourites1,
  Favourites2,
  Friends1,
  Friends2,
} from 'components/@icons';
import Link from 'components/@navigation/Link';

type SimpleBottomNavigationProps = { currentPath: string };

const items = [
  {
    label: 'navigation.bottom.home',
    value: Routes.home,
    href: Routes.home,
    Icon: <Discover1 width={24} height={24} />,
    IconGradient: <Discover2 width={24} height={24} />,
  },
  {
    label: 'navigation.bottom.subscription',
    value: Routes.subscriptions,
    href: Routes.subscriptions,
    Icon: <AccountBalanceWallet1 width={24} height={24} />,
    IconGradient: <AccountBalanceWallet2 width={24} height={24} />,
  },
  {
    label: 'navigation.bottom.favorites',
    value: Routes.favorites,
    href: Routes.favorites,
    Icon: <Favourites1 width={24} height={24} />,
    IconGradient: <Favourites2 width={24} height={24} />,
  },
  {
    label: 'navigation.bottom.friends',
    value: Routes.friends,
    href: Routes.friends,
    Icon: <Friends1 width={24} height={24} />,
    IconGradient: <Friends2 width={24} height={24} />,
  },
];

const SimpleBottomNavigation = ({ currentPath }: SimpleBottomNavigationProps) => {
  const [value, setValue] = React.useState(currentPath);
  const intl = useIntl();

  useEffect(() => {
    if (currentPath !== value) setValue(currentPath);
  }, [currentPath, value]);

  const menuItems = useMemo(() => {
    if (currentPath.includes(Routes.collectionBottomNavigation)) {
      return items.filter(item => item.value !== Routes.discover);
    } else {
      return items.filter(item => item.value !== Routes.collectionBottomNavigation);
    }
  }, [currentPath]);

  return (
    <Paper
      component="nav"
      style={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        value={value}
        onChange={(_event, newValue) => {
          setValue(newValue);
        }}
        showLabels
      >
        {menuItems.map((item, idx) => {
          const isActive = value === item.value;
          const label = intl.formatMessage({ id: item.label });

          return (
            <BottomNavigationAction
              key={idx}
              component={Link}
              href={item.href}
              label={isActive ? <GradientTypography>{label}</GradientTypography> : label}
              icon={isActive ? item.IconGradient : item.Icon}
              value={item.value}
            />
          );
        })}
      </BottomNavigation>
    </Paper>
  );
};

export default SimpleBottomNavigation;

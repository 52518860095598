import { Url } from 'types/global.types';

import { AvailabilityType, GameTeaser } from './collections.definitions';

export interface GameSsrInfo {
  id: string;
  slugName: string;
}

export interface InfiniteGames {
  loadMoreUrl: string | null;
  games: GameTeaser[];
}

export enum GameProvider {
  Newcom = 'Newcom',
  Gameloft = 'Gameloft',
  Nazara = 'Nazara',
}

export interface GameBase {
  id: string;
  name: string;
  slugName: string;
  shortCode: string;
  collection: string;
  smallTeaserImage: Url;
  largeTeaserImage: Url;
  price: string;
  subscriptionType: SubscriptionType;
  availability: AvailabilityType;
  gameType: GameType;
  type: TypeOfGame;
  isGameOfChance: boolean;
  leaderboard: boolean;
  description: string;
  exampleImages: Url[];
  metadata: MetaData[];
  recommendedGames?: GameTeaser[];
  url: Url;
  webviewRequiresToken: boolean;
  provider?: GameProvider; // TODO: spec says required. But currently not in response
}

export enum ContentType {
  game = 'game',
  subscription = 'subscription',
}
export interface GameSubscription extends GameBase {
  subscribeUrl: Url;
  unsubscribeUrl: Url;
  contentType: ContentType.subscription;
}
export interface Game extends GameBase {
  contentType: ContentType.game;
}

export enum TypeOfGame {
  scratch = 'scratch',
  token = 'token',
  quiz = 'quiz',
  raffle = 'raffle',
  vote = 'vote',
  webview = 'webview',
  slot = 'slot',
  wheel = 'wheel',
}

export enum GameType {
  firstParty = 'FirstParty',
  thirdParty = 'ThirdParty',
  service = 'Service',
}

export enum SubscriptionType {
  perGameSession = 'PerGameSession',
  oneTimePurchase = 'OneTimePurchase',
  inGamePurchases = 'InGamePurchases',
  perMessage = 'PerMessage',
}

export type MetaData = { [key: string]: string };
export interface LeaderBoards {
  userRank: number;
  userPoints: number;
  totalPlayers: number;
  friendsLeaderboard: Player[];
  totalLeaderboard: Player[];
}

export type Player = {
  id: string;
  username: string;
  avatar: Url;
  rank: number;
  points: number;
};

import * as React from 'react';

function SvgBack(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 25" {...props}>
      <defs>
        <clipPath id="Back_svg__a">
          <path d="M0 0h25v25H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#Back_svg__a)">
        <path
          d="M23 11.75H7.787l6.988-6.988L13 3 3 13l10 10 1.762-1.762-6.975-6.988H23z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

const MemoSvgBack = React.memo(SvgBack);
export default MemoSvgBack;

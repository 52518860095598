import * as React from 'react';

function SvgDiscover1(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 25" {...props}>
      <defs>
        <clipPath id="Discover_1_svg__b">
          <path transform="translate(111 3106)" fill="#7a7a97" stroke="#707070" d="M0 0h20v20H0z" />
        </clipPath>
        <clipPath id="Discover_1_svg__a">
          <path d="M0 0h25v25H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#Discover_1_svg__a)">
        <g transform="translate(-108 -3103)" clipPath="url(#Discover_1_svg__b)">
          <g fill="#7a7a97">
            <path d="M119.339 3126h-7.47l-.87-.87v-7.47l.87-.87h7.47l.87.87v7.469zm-6.6-1.739h5.73v-5.732h-5.73zM130.129 3126h-7.47l-.87-.87v-7.47l.87-.87h7.47l.87.87v7.469zm-6.6-1.739h5.73v-5.732h-5.73zM119.339 3115.208h-7.47l-.87-.87v-7.469l.87-.87h7.47l.87.87v7.47zm-6.6-1.739h5.73v-5.73h-5.73zM130.129 3115.208h-7.47l-.87-.87v-7.469l.87-.87h7.47l.87.87v7.47zm-6.6-1.739h5.73v-5.73h-5.73z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

const MemoSvgDiscover1 = React.memo(SvgDiscover1);
export default MemoSvgDiscover1;

import React, { useState } from 'react';

import { AppBar, AppBarProps, Box, Icon, IconButton, makeStyles, Toolbar } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Skeleton } from '@material-ui/lab';
import { useAuth, useBackRouter } from 'hooks';
import useMounted from 'hooks/mounted';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { FormattedMessage } from 'react-intl';
import { Routes } from 'routes';

import GameIcon from 'components/@common/GameIcon';
import { Back, Close, Menu } from 'components/@icons';
import ElevationScroll from 'components/@navigation/ElevationScroll';
import Link from 'components/@navigation/Link';

const MenuDrawer = dynamic(() => import('../MenuDrawer'));

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'unset',
    flexGrow: 1,
    textAlign: 'center',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  language: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 1,
    textTransform: 'uppercase',
  },
  button: {
    marginTop: theme.spacing(5),
  },
  backButton: {
    color: theme.palette.text.primary,
    marginLeft: -4,
  },
}));

export type HeaderVariant = 'default' | 'backbutton' | 'disableDrawer';

interface Props {
  variant: HeaderVariant;
  appBarPosition?: AppBarProps['position'];
  backAction?: () => void;
}

const Header: React.FC<Props> = ({ variant = 'default', appBarPosition, backAction }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const router = useBackRouter();
  const classes = useStyles();
  const { isLoggedIn, user } = useAuth();
  const isMounted = useMounted();
  const handleIconClick = () => {
    setIsMenuOpen(prev => !prev);
  };

  return (
    <>
      {variant === 'default' && (
        <MenuDrawer open={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
      )}
      <ElevationScroll isMenuOpen={isMenuOpen} disabled={appBarPosition === 'relative'}>
        <AppBar position={appBarPosition}>
          <Toolbar variant="dense">
            {variant === 'default' && (
              <IconButton
                className={classes.menuButton}
                edge="start"
                onClick={handleIconClick}
                aria-label="menu"
                size="small"
              >
                {isMenuOpen ? <Close /> : <Menu />}
              </IconButton>
            )}
            {variant === 'backbutton' && (
              <IconButton
                edge="start"
                aria-label="back"
                color="inherit"
                onClick={backAction ? backAction : () => router.back()}
                className={classes.backButton}
              >
                <Back />
              </IconButton>
            )}

            <Box display="flex" justifyContent="center" width="100%" alignItems="center">
              <Link href={Routes.home}>
                <Image
                  priority
                  src="/branding/img-logo-gamenation.svg"
                  alt="game nation logo"
                  height={27}
                  width={97}
                />
              </Link>
            </Box>
            <Box width={32}>
              {(!isMounted && !isLoggedIn) || (!user && isLoggedIn) ? (
                <Skeleton width={32} height={32} variant="circle" />
              ) : isLoggedIn && user && user.avatar ? (
                <Link href={Routes.profile} aria-label="view settings">
                  <GameIcon
                    src={user.avatar}
                    alt={user.username}
                    size={32}
                    borderRadius="50%"
                    imagesAboveFold
                  />
                </Link>
              ) : (
                <Link
                  href={Routes.language}
                  color="textSecondary"
                  underline="hover"
                  className={classes.language}
                >
                  <FormattedMessage id={`language.shortcode.${router.locale}`} />
                  <Icon>
                    <ArrowDropDownIcon />
                  </Icon>
                </Link>
              )}
            </Box>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </>
  );
};

export default Header;

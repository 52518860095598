import React, { useContext } from 'react';

import { useRouter } from 'next/router';
import { Routes } from 'routes';

import { RouterContext } from 'services/router/RouterProvider';

export const useBackRouter = (fallbackRoute: string = Routes.home) => {
  const router = useRouter();
  const prevRoute = useContext(RouterContext);

  const back = React.useCallback(() => {
    if (prevRoute) {
      router.back();
    } else {
      router.replace(fallbackRoute);
    }
  }, [fallbackRoute, prevRoute, router]);

  return { ...router, back };
};

import * as React from 'react';

function SvgFriends2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 25" {...props}>
      <defs>
        <linearGradient
          id="Friends_2_svg__b"
          y1={1}
          x2={0.864}
          y2={0.056}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#d331fb" />
          <stop offset={0.1} stopColor="#c33efb" />
          <stop offset={0.2} stopColor="#b34cfb" />
          <stop offset={0.3} stopColor="#a459fb" />
          <stop offset={0.4} stopColor="#9467fb" />
          <stop offset={0.5} stopColor="#8575fb" />
          <stop offset={0.6} stopColor="#7583fb" />
          <stop offset={0.7} stopColor="#6590fb" />
          <stop offset={0.8} stopColor="#569efb" />
          <stop offset={0.9} stopColor="#46acfb" />
          <stop offset={1} stopColor="#36bafb" />
        </linearGradient>
        <clipPath id="Friends_2_svg__a">
          <path d="M0 0h25v25H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#Friends_2_svg__a)">
        <path
          d="M3.042 19.385l-2.311-.624L0 17.5l1.461-5.53.825-.745 1.936-.31a20.861 20.861 0 016.761.024l1.724.286.807.707 1.686 5.523-.722 1.3-2.317.626A15.486 15.486 0 017.862 20a19.6 19.6 0 01-4.82-.615zM4.6 13.291l-1.045.167-.882 3.335.994.269a15.086 15.086 0 007.861 0l.951-.257-1.019-3.347-.876-.146a18.6 18.6 0 00-5.984-.021zm10.563 2.125l2.112-.571-1.016-3.345a14.4 14.4 0 00-3.938-.559L12.2 8.673l.4-.021A2.7 2.7 0 0015.189 5.9c0-1.958-1.156-2.71-2.236-2.761l-.4-.022.02-.41h-.019l.076-1.6h.019l.019-.387.4.019a4.5 4.5 0 013.313 1.6A5.513 5.513 0 0117.6 5.9a5.178 5.178 0 01-1.1 3.2l1.01.168.806.707 1.676 5.525-.722 1.3-3.478.94zM2.4 5.2a5.2 5.2 0 115.2 5.2 5.206 5.206 0 01-5.2-5.2zm2.407 0A2.794 2.794 0 107.6 2.406 2.8 2.8 0 004.807 5.2z"
          transform="translate(3 3)"
          fill="url(#Friends_2_svg__b)"
        />
      </g>
    </svg>
  );
}

const MemoSvgFriends2 = React.memo(SvgFriends2);
export default MemoSvgFriends2;

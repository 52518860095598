import * as React from 'react';

function SvgFavourites1(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 25" {...props}>
      <defs>
        <clipPath id="Favourites_1_svg__b">
          <path transform="translate(186 3106)" fill="#7a7a97" stroke="#707070" d="M0 0h20v20H0z" />
        </clipPath>
        <clipPath id="Favourites_1_svg__a">
          <path d="M0 0h25v25H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#Favourites_1_svg__a)">
        <g transform="translate(-183 -3103)" clipPath="url(#Favourites_1_svg__b)">
          <path
            d="M196.628 3125.014h-1.253l-7.673-7.83a6.09 6.09 0 010-8.447 5.394 5.394 0 011.12-.87 5.785 5.785 0 017.177.87l.174-.174a5.785 5.785 0 018.125.183 6.09 6.09 0 010 8.439zm-4.776-16.338a3.984 3.984 0 00-2.114.6 3.645 3.645 0 00-.783.626 4.35 4.35 0 000 6.029l7.047 7.229 7.055-7.238a4.35 4.35 0 000-6.02 4.045 4.045 0 00-5.672-.13 4.124 4.124 0 00-.635.774h-1.479a4.01 4.01 0 00-3.419-1.87z"
            fill="#7a7a97"
          />
        </g>
      </g>
    </svg>
  );
}

const MemoSvgFavourites1 = React.memo(SvgFavourites1);
export default MemoSvgFavourites1;

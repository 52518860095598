import * as React from 'react';

function SvgDiscover2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 25" {...props}>
      <defs>
        <clipPath id="Discover_2_svg__c">
          <path
            transform="translate(111 3003)"
            stroke="#707070"
            fill="url(#Discover_2_svg__a)"
            d="M0 0h20v20H0z"
          />
        </clipPath>
        <clipPath id="Discover_2_svg__b">
          <path d="M0 0h25v25H0z" />
        </clipPath>
        <linearGradient
          id="Discover_2_svg__a"
          x1={0.297}
          y1={1.037}
          x2={0.656}
          y2={0.194}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#d331fb" />
          <stop offset={1} stopColor="#37bafc" />
        </linearGradient>
      </defs>
      <g clipPath="url(#Discover_2_svg__b)">
        <g transform="translate(-108 -3000)" clipPath="url(#Discover_2_svg__c)">
          <path
            d="M-4534.985-17743l-1.182-1.182v-6.8l1.182-1.186h6.8l1.181 1.186v6.8l-1.181 1.182zm1.195-2.371h4.413v-4.418h-4.413zm-12.028 2.371l-1.181-1.182v-6.8l1.181-1.186h6.8l1.182 1.186v6.8l-1.182 1.182zm1.195-2.371h4.414v-4.418h-4.414zm9.638-8.461l-1.182-1.182v-6.8l1.182-1.186h6.8l1.181 1.186v6.8l-1.181 1.182zm1.195-2.377h4.413v-4.414h-4.413zm-12.028 2.377l-1.181-1.182v-6.8l1.181-1.186h6.8l1.182 1.186v6.8l-1.182 1.182zm1.195-2.377h4.414v-4.414h-4.414z"
            transform="translate(4658 20766)"
            fill="url(#Discover_2_svg__a)"
          />
        </g>
      </g>
    </svg>
  );
}

const MemoSvgDiscover2 = React.memo(SvgDiscover2);
export default MemoSvgDiscover2;

import * as React from 'react';

function SvgMenu(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 25" {...props}>
      <defs>
        <clipPath id="Menu_svg__a">
          <path d="M0 0h25v25H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#Menu_svg__a)">
        <g transform="translate(-20 -27)" fill="currentColor">
          <rect width={25} height={3} rx={1.5} transform="translate(20 30)" />
          <rect width={25} height={3} rx={1.5} transform="translate(20 38.5)" />
          <rect width={25} height={3} rx={1.5} transform="translate(20 47)" />
        </g>
      </g>
    </svg>
  );
}

const MemoSvgMenu = React.memo(SvgMenu);
export default MemoSvgMenu;

import * as React from 'react';

function SvgFriends1(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 25" {...props}>
      <defs>
        <clipPath id="Friends_1_svg__b">
          <path transform="translate(261 3106)" fill="#7a7a97" stroke="#707070" d="M0 0h20v20H0z" />
        </clipPath>
        <clipPath id="Friends_1_svg__a">
          <path d="M0 0h25v25H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#Friends_1_svg__a)">
        <g transform="translate(-258 -3103)" clipPath="url(#Friends_1_svg__b)">
          <g fill="#7a7a97">
            <path d="M268.522 3116.047a5.024 5.024 0 115.02-5.023 5.024 5.024 0 01-5.02 5.023zm0-8.3a3.286 3.286 0 103.277 3.277 3.286 3.286 0 00-3.277-3.277zM268.527 3126a19.217 19.217 0 01-4.615-.556l-2.238-.548-.622-1.068 1.443-4.954.7-.617 1.895-.269a23.607 23.607 0 016.867 0l1.669.252.7.582 1.648 4.936-.617 1.139-2.234.548a19.122 19.122 0 01-4.6.556zm-5.553-2.608l1.356.33a17.81 17.81 0 008.4 0l1.312-.322-1.191-3.538-1.147-.174a21.677 21.677 0 00-6.362 0l-1.33.191zM279.314 3116.205l-.339-.461a12.7 12.7 0 00-1.6-.869 4.346 4.346 0 001.147-3.094 4.659 4.659 0 00-4.442-4.659l-.087 1.738a2.929 2.929 0 012.79 2.929 2.295 2.295 0 01-2.373 2.608l.087 1.738h1.017a5.867 5.867 0 012.225.965l1.3 4.294-.643.174.435 1.591 1.5-.409.608-1.1z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

const MemoSvgFriends1 = React.memo(SvgFriends1);
export default MemoSvgFriends1;
